import { domReady, qsa } from 'lib/dom.js'
import { on, stop } from 'lib/events.js'

domReady(_ => {
	qsa('a[data-confirm]').forEach(link => {
		on(link, 'click', clickEvent => {
			if (!confirm(link.dataset.confirm)) {
				stop(clickEvent)
			}
		})
	})
})
